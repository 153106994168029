.feedbackModal {
  margin: 10px auto;
  background-color: #202124;
  padding: 14px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  text-align: center;

  .closeHolder {
    display: flex;
    justify-content: flex-end;
    margin: 0;

    .closeButton {
      color: #bbb;
      cursor: pointer;
      border: 0;
      background: none;
      font-size: 18px;

      &:hover {
        color: #eee;
      }
    }
  }

  p {
    margin: 0;
    margin-bottom: 6px;
  }

  form > div {
    margin-bottom: 12px;
  }

  form > button {
    background-color: #ffffff20;
    border: 1px solid #fff;
    color: #fff;
    padding: 8px 14px;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: #ffffff60;
    }

    &:focus {
      outline: none;
    }
  }

  textarea,
  input {
    border-radius: 10px;
    border: 1px solid #666;
    background-color: #ffffff20;
    color: #eee;
    font-size: 16px;
    padding: 6px 8px;
    max-width: 80vw;
    max-height: 60vh;

    &:focus {
      outline: solid #333;
    }
  }
}
