.VersionsInfo {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #ddd;
  font-size: 14px;
  opacity: 0.4;
  transition: opacity 0.1s;
  z-index: -100;

  &:hover {
    opacity: 0.9;
  }
}
