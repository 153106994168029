.BackButton {
  height: 36px;
  width: 36px;
  background-color: #1b8ee020;
  border: 1px solid #1b8ee0;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: background-color 0.1s;
  color: #fff;

  &:hover {
    background-color: #1b8ee060;
  }
}
