.Home {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .home-menu {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-end;

    .SquareButton {
      margin: 10px;
    }
  }

  .backbutton-holder {
    z-index: 5;
    position: absolute;
    top: 35px;
    left: 20px;
  }
}
