.ThinSeparator {
  position: relative;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  opacity: 0.9;
  // For removal in case of need
  color: #fff;
  font-size: 18px;

  &::before, &::after {
    content: "";
    display: block;
    background-color: #fff;
    align-self: center;
  }

  &.horizontal {
    margin: 10px 0;

    &::before, &::after {
      height: 1px;
      width: 50px;
      margin: 0 10px;
    }
  }

  &.vertical {
    flex-direction: column;
    margin: 0 10px;

    &::before, &::after {
      height: 50px;
      width: 1px;
      margin: 10px 0px;
    }
  }
}
