.WaitingScreen {
  color: #eee;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;

  .player-row {
    display: flex;
    align-items: center;

    div {
      margin: 0 6px;
    }

    .status {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: gray;
      border: 1px solid #111;

      &.ready {
        background-color: rgb(61, 194, 43);
      }
    }
  }
}
