.SmallButton {
  display: block;
  padding: 4px 10px;
  background-color: #d4d4d4;
  font-size: 0.76em;
  font-weight: bold;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.1s;

  &:hover {
    background-color: #eee;
  }
  &:focus {
    outline: none;
  }
}
