.PopupMessages {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 500;

  @media (max-width: 600px) {
    width: 70%;
  }
}

.PopupMessageBox {
  margin: 8px auto;
  border-radius: 4px;
  padding: 6px 14px;
  max-width: 300px;
  text-align: justify;
  animation-name: PopupShow;
  animation-duration: 0.5s;

  border: 1px solid #fff;
  background-color: #ffffff20;
  color: #fff;

  &.red {
    border: 1px solid #a82e2e;
    background-color: #81262680;
    color: #ffcfcf;
  }

  &.yellow {
    border: 1px solid #a8a62e;
    background-color: #80812680;
    color: #feffcf;
  }

  &.blue {
    border: 1px solid #2ea8a2;
    background-color: #266c8180;
    color: #cff5ff;
  }
}

@keyframes PopupShow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
