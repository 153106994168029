.MovieCountdown {
  position: relative;
  font-size: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 50px; // So that elements beside don't go inside of circle

  &::after {
    position: absolute;
    content: ' ';
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    height: 200px;
    width: 200px;
    animation-name: expandCircle;
    animation-iteration-count: infinite;
    animation-duration: 1s;
  }
}

@keyframes expandCircle {
  0% {
    height: 50px;
    width: 50px;
  }
  100% {
    height: 240px;
    width: 240px;
  }
}
