.TopUserBar {
  position: relative;

  .toggle {
    position: absolute;
    top: 4px;
    left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #eee;
    cursor: pointer;
    background-color: #444;
    height: 26px;
    width: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 50%;
    opacity: 0.4;
    user-select: none;
    transition: 0.1s;
    z-index: 200;

    &.opened {
      opacity: 0.6;
      transform: rotate(180deg);
    }

    &:hover {
      opacity: 0.9;
    }
  }

  .bar {
    max-height: 0px;
    box-sizing: border-box;
    padding-left: 46px;
    background-color: #1c1e23;
    color: #eee;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: max-height 0.1s;

    .left-side {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 50%;
    }

    .right-side {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      margin-left: auto;
      margin-right: 1vw;
      margin-bottom: 3px;
      margin-top: 3px;

      > div {
        margin: 3px 5px;
        overflow: hidden;
        white-space: nowrap;

        &.username {
          max-width: 20vw;
          text-overflow: ellipsis;
        }
      }
    }

    &.opened {
      max-height: 70px;
    }
  }
}
