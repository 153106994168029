.About {
  color: #eee;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;
  flex-basis: 0;

  // Hacky way to make the main element more centered
  > :first-child {
    margin-top: 14px;
  }
  > :last-child {
    margin-bottom: 14px;
  }

  h2,
  h3 {
    margin: 0;
  }

  h3 {
    font-size: 0.9rem;
    font-weight: 500;
  }

  h2 {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    background-color: #eee;
    color: #282c34;
    padding: 0 8px;
  }

  .flip-board {
    font-weight: 500;
    margin: 10px 0;
    overflow: hidden;
    text-align: center;
    scroll-behavior: smooth;
  }

  .games-list {
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    max-width: 90vw;

    .game-box {
      background-color: #eee;
      color: #282c34;
      margin: 4px;
      padding: 2px 4px;
      opacity: 0.9;
      transition: opacity 0.2s;
      cursor: default;

      &:hover {
        opacity: 1;
      }
    }
  }
}
