.CreateLobby {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  text-align: center;
  color: #fff;

  .selected-games-holder {
    $bg-color: #20242a;

    display: flex;
    background-color: $bg-color;
    overflow: auto;
    min-height: 124px;
    padding-left: 78px;
    position: relative;

    &::before {
      content: 'Your playlist';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.3;
      text-transform: uppercase;
      font-size: 1.1rem;
      font-weight: 500;
    }

    &::-webkit-scrollbar {
      height: 12px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px #1a1a1a;
      border: solid 3px transparent;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px #ccc;
      border: solid 4px transparent;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      box-shadow: inset 0 0 10px 10px #aaa;
      border: solid 3px transparent;
    }

    .selected-games {
      background-color: $bg-color;
      display: flex;
      z-index: 2;
    }
  }

  .games-list-title {
    margin-top: 8px;
    margin-bottom: 4px;
    text-transform: uppercase;
    font-weight: 500;
    opacity: 0.8;
  }

  .games-list-holder {
    flex: 1;
    overflow: auto;
    max-width: 900px;
    margin: 0 auto;
    padding: 2px;

    .games-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      overflow: auto;
    }
  }

  .create-game {
    margin: 10px 0;

    button {
      background-color: #ffffff20;
      border: 1px solid #fff;
      color: #fff;
      padding: 8px 14px;
      font-size: 18px;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background-color: #ffffff60;
      }
      &:focus {
        outline: none;
      }
      &:disabled {
        opacity: 0.8;
        background-color: #ffffff20;
        cursor: not-allowed;
      }
    }
  }
}
