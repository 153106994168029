.BlackOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
}
