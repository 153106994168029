@import 'src/utils/colors/colors.scss';

.InLobbyBox {
  flex: 1;
  color: #fff;
  overflow: auto;

  p {
    margin-top: 40px;
  }

  .games-list,
  .lobby-details,
  .basic-box {
    max-width: 400px;
    box-sizing: border-box;
  }

  .games-list {
    max-width: none;
    width: 100%;
    display: flex;
    background-color: #00000030;
    overflow: auto;
    min-height: 124px;
    padding-left: 78px;
  }

  .lobby-details {
    margin: 0 auto;
    padding: 0 8px;
    text-align: center;

    .join-code-holder {
      display: flex;
      width: 100%;
      margin: 8px 0;
      font-size: 22px;
      text-align: center;

      div {
        padding: 10px 20px;
        flex: 1;
      }

      .text {
        background-color: #242830;
      }
      .code {
        background-color: #fff;
        color: #000;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .basic-box {
      width: 100%;
      text-align: center;
      background-color: #242830;
      padding: 10px 16px;
      margin: 8px 0;

      .small-text {
        font-size: 0.8em;
        color: #bbb;
      }

      &.is-me {
        font-weight: bold;
      }

      &.player-box {
        display: flex;
        padding: 0;

        .color-box {
          width: 18px;

          @include colors-list;
        }

        .elo {
          align-self: center;
          padding: 0 6px;
          font-size: 0.8em;
          opacity: 0.8;
        }

        .player-nick {
          flex: 1;
          padding: 10px 16px;
        }

        .ready-button {
          display: block;
          padding: 0 14px;
          cursor: pointer;
          border: 0;
          font-weight: bold;
          background-color: #b63d3d;
          opacity: 0.7;
          transition: opacity 0.1s;

          &.is-ready {
            background-color: #3db661;
          }

          &:hover {
            opacity: 1;
          }

          &:disabled {
            opacity: 1;
            cursor: default;
            color: initial;
          }

          .small-text {
            font-size: 0.8em;
            font-weight: normal;
            color: inherit;
          }
        }
      }
    }
  }

  // TODO: Convert basic button to common component
  .leave-lobby-button {
    margin-top: 20px;

    background-color: #ffffff20;
    border: 1px solid #fff;
    color: #fff;
    padding: 8px 14px;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: #ffffff60;
    }
    &:focus {
      outline: none;
    }
    &:disabled {
      opacity: 0.8;
      background-color: #ffffff20;
      cursor: not-allowed;
    }
  }
}
