.SocketReconnector {
  .reconnect-box {
    border: 1px solid #a82e2e;
    background-color: #81262680;
    color: #ffcfcf;
    border-radius: 4px;
    margin: 10px;
    padding: 10px;
    text-align: center;

    p {
      font-size: 17px;

      &.note {
        font-size: 14px;
      }
    }

    button {
      width: 100%;
      border: 1px solid #a82e2e;
      background-color: rgba(0,0,0,0.3);
      color: #ffdfdf;
      border-radius: 4px;
      padding: 8px;
      font-size: 18px;
      cursor: pointer;
      transition: background-color 0.1s;

      &:hover {
        background-color: rgba(0,0,0,0.5);
      }
      &:active {
        background-color: rgba(0,0,0,0.7);
      }
      &:focus {
        outline: none;
      }
    }

    .connecting {
      padding: 9px;
    }
  }
}
