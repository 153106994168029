.login-holder {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:after {
    content: '';
    flex: 1;
    flex-basis: 0;
  }
}

.Login {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: #eee;
    opacity: 0.6;
    transition: opacity 0.1s;
  }
  a:hover {
    opacity: 1;
  }

  &.smallWidth {
    flex-direction: column;
  }

  .user-login {
    input {
      display: block;
      border: 1px solid #fff;
      background-color: #ffffff20;
      color: #fff;
      font-size: 16px;
      padding: 6px 8px;
      border-radius: 5px;
      margin: 10px 0;

      &:focus {
        outline: none;
        background-color: #ffffff25;
      }
    }

    .submit-buttons {
      display: flex;

      button {
        display: block;
        width: 100%;
        border: 1px solid #fff;
        background-color: #ffffff20;
        color: #fff;
        padding: 6px 8px;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;

        &:first-of-type {
          margin-right: 10px;
        }

        &:hover {
          background-color: #ffffff60;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .forgot-password-holder {
      margin-top: 4px;
    }
  }
}
