@import 'src/utils/colors/colors.scss';

.PlayersSidePanel {
  background-color: #242830;
  color: #fff;

  .top_row {
    align-items: center;
    margin: 10px;
    margin-bottom: 50px; // TODO: Change to flex
    display: flex;
    background-color: #272b33;
    font-size: 22px;

    .text {
      flex: 1;
      padding: 0 10px;
    }

    .box {
      background-color: #fff;
      color: #000;
      font-weight: bold;
      padding: 4px 10px;
    }
  }

  .players_list {
    .player_row {
      display: flex;
      background-color: #272b33;
      margin: 10px;
      font-size: 16px;
      border-left: 3px solid transparent;

      &.is_me {
        font-weight: bold;
      }

      &.active {
        border-left: 3px solid white;
      }

      &.offline {
        opacity: 0.4;
      }

      .nickname {
        flex: 1;
        padding: 6px 12px;
      }

      .points {
        background-color: white;
        color: #000;
        padding: 6px 12px;
        display: flex;
        align-items: center;

        @include colors-list;

        .points_added {
          font-size: 0.75em;
          animation-name: expand;
          animation-duration: 1s;
          animation-iteration-count: 1;
          overflow: hidden;
        }
        @keyframes expand {
          from {
            max-width: 0px;
          }
          to {
            max-width: 50px;
          }
        }
      }
    }
  }

  &.mobile_view {
    height: 100%;
    flex: 0 0 100%;

    .close_button {
      display: block;
      font-size: 18px;
      margin: 6px;
      margin-left: auto;
      background-color: transparent;
      color: #fff;
      border: 2px solid #fff;
      font-weight: bold;
      border-radius: 4px;
      padding: 4px 8px;
    }
  }

  &.minimal {
    display: flex;

    .top_row {
      margin: 1px;
      font-size: 16px;
      background-color: #fff;

      .box {
        padding: 5px 10px;
      }
    }

    .players_list {
      display: flex;
      flex: 1;
      overflow: hidden;

      .player_row {
        font-size: 13px;
        flex: 1;
        min-width: 0;
        margin: 0;
        border-left: 0;
        align-items: center;
        border: 1px solid transparent;

        &.active {
          border: 1px solid #fff;
        }

        .nickname {
          flex: 1;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 1vw;
        }

        .points {
          display: flex;
          text-align: center;
          justify-content: center;
          padding: 4px 16px;
          align-self: stretch;
        }
      }
    }
  }
}
