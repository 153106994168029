$box-size: 80px;

.LoadingSquares {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  height: $box-size;
  width: $box-size;

  animation-name: boxExpand;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;

  .square {
    position: absolute;
    height: $box-size - ($box-size/2);
    width: $box-size - ($box-size/2);
    background-color: rgba(255, 255, 255, 0.95);

    animation-duration: 1.5s;
    animation-iteration-count: infinite;

    &:nth-of-type(1) {
      animation-name: squareSpin;
      top: 0;
      left: 0;
    }
    &:nth-of-type(2) {
      animation-name: squareSpin;
      top: 0;
      right: 0;
    }
    &:nth-of-type(3) {
      animation-name: squareSpin;
      bottom: 0;
      right: 0;
    }
    &:nth-of-type(4) {
      animation-name: squareSpin;
      bottom: 0;
      left: 0
    }
  }
}

@keyframes boxExpand {
  0% {
    width: $box-size;
    height: $box-size;
  }
  40% {
    width: 140px;
    height: 140px;
  }
  60% {
    width: 140px;
    height: 140px;
  }
  90% {
    width: $box-size;
    height: $box-size;
  }
  100% {
    width: $box-size;
    height: $box-size;
  }
}

@keyframes squareSpin {
  20% { transform: rotate(0deg) }
  100% { transform: rotate(90deg) }
}
