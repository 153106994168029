.RejoinGame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  text-align: center;
  color: #fff;

  .game-row {
    display: flex;
    align-items: center;
    background-color: #2a303b;
    margin: 4px auto;
    padding: 0 8px;
    width: 100%;
    max-width: 400px;

    .game-id {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .rightside {
      display: flex;
      margin-left: auto;
    }

    .game-name {
      background-color: #3a4252;
      text-align: center;
      margin: 0 2px;
      padding: 0 4px;
    }

    .rejoin-button {
      margin: 0 2px;
      padding: 0px 14px;
      cursor: pointer;
      background-color: #3c9a51;
      border: 0;
      color: #fff;
      transition: background-color 0.1s;

      &:hover {
        background-color: #338645;
      }
    }
  }
}
