.LobbyList {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  max-width: 900px;

  .no-lobbies-message {
    color: #eee;
    font-size: 18px;
    text-align: center;
    background-color: #11111188;
    padding: 10px;
    margin: 0 5px;
  }

  table {
    text-align: center;
    color: #eee;
    border-collapse: separate;
    border-spacing: 0 5px;
    margin: 5px;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    align-self: center;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px #1a1a1a;
      border: solid 3px transparent;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px #ccc;
      border: solid 3px transparent;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      box-shadow: inset 0 0 10px 10px #aaa;
      border: solid 2px transparent;
    }

    th {
      padding: 10px;
    }

    td {
      padding: 12px;
      background-color: #1a1a1a;

      &.flexbox {
        display: flex;
        overflow: auto;
        max-width: 40vw; // TODO: Find a better approach to scaling

        .game {
          background-color: #444;
          border-radius: 5px;
          margin: 2px;
          padding: 0 6px;
        }

        &::-webkit-scrollbar {
          height: 10px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 10px 10px #222;
          border: solid 3px transparent;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 10px 10px #4563eb;
          border: solid 3px transparent;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
          box-shadow: inset 0 0 10px 10px #4563eb;
          border: solid 2px transparent;
        }
      }
    }

    .selected {
      td {
        background-color: #3a3a3a;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    button {
      display: block;
      margin: 6px 5px;
      padding: 8px 6px;
      cursor: pointer;
    }
  }
}
