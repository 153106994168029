.JoinLobby {
  margin: 0 auto;
  margin-top: 16px;

  input {
    border: 1px solid #fff;
    background-color: #ffffff20;
    color: #fff;
    font-size: 16px;
    padding: 6px 8px;
    border-radius: 5px 0 0 5px;
    text-transform: uppercase;

    &:focus {
      outline: none;
      background-color: #ffffff25;
    }
  }

  button {
    border: 1px solid #fff;
    border-left: 0;
    background-color: #ffffff20;
    color: #fff;
    padding: 6px 8px;
    font-size: 16px;
    border-radius: 0 5px 5px 0;
    cursor: pointer;

    &:hover {
      background-color: #ffffff60;
    }
    &:focus {
      outline: none;
    }
  }
}
