.Settings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  text-align: center;
  color: #fff;
  align-items: center;

  .setting {
    margin-bottom: 8px;

    .option-note {
      margin: 0;
      font-size: 11px;
      color: #ffffffcc;
    }
  }

  button {
    background-color: #ffffff20;
    border: 1px solid #fff;
    color: #fff;
    padding: 8px 14px;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: #ffffff60;
    }
    &:focus {
      outline: none;
    }
  }

  .credits {
    margin-top: 30px;

    a {
      color: #eee;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }
  }
}
