.Matchmaking {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  text-align: center;
  color: #fff;
  margin: 0 auto;

  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .search-info {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-right: 10px;
    }
  }

  .search-button {
    background-color: #ffffff20;
    border: 1px solid #fff;
    color: #fff;
    padding: 8px 14px;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: #ffffff60;
    }
    &:focus {
      outline: none;
    }
    &:disabled {
      opacity: 0.8;
      background-color: #ffffff20;
      cursor: not-allowed;
    }
  }

  .games-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .players-waiting {
      background-color: #ffffff10;
      margin: 4px 2px;
      border-radius: 5px;
    }

    .game {
      transition: 0.1s opacity;
      opacity: 0.5;

      &:hover {
        opacity: 0.8;
      }

      &.added {
        opacity: 1;
      }
    }
  }
}
