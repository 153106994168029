.GamesCoordinator {
  display: inherit;
  height: inherit;
  flex-direction: inherit;

  &.game {
    flex-direction: row;
    overflow: hidden;

    &.mobile_view {
      flex-direction: column;
    }
  }

  &.leaderboard {
    justify-content: center;
    overflow: hidden;

    .flexList {
      display: flex;
      align-items: center;
      overflow-x: auto;
      height: 100%;
      width: 100%;
      margin: 0 auto;

      @media only screen and (max-width: 600px) {
        margin: 0;
      }
    }
  }

  &.hidden {
    display: none;
  }
}
