.BattleFinishedBox {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 2px;
  text-align: center;

  .top {
    padding: 10px 14px;
    border-bottom: 1px solid black;
  }
  .bottom {
    padding: 14px 20px;

    .winner {
      font-weight: bold;
    }

    button {
      margin-top: 10px;
      cursor: pointer;
      background-color: #1f6b6b10;
      border: 1px solid #1f6b6b;
      color: #1f6b6b;
      font-size: 18px;
      border-radius: 4px;
      padding: 4px 6px;
      outline: none;
      transition: 0.05s;

      &:hover {
        background-color: #1f6b6b;
        color: #fff;
      }
    }
  }
}

.BattleFinishedButton {
  cursor: pointer;
  margin-top: 10px;
  background-color: #aaaaaa10;
  border: 0;
  color: #ccc;
  font-size: 18px;
  padding: 10px 8px;
  outline: none;
  transition: background-color 0.1s, color 0.1s;

  &:hover {
    background-color: #ccc;
    color: #333;
  }
}
