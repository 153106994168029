.readycheckModal {
  margin: 10px auto;
  background-color: #202124;
  padding: 14px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  text-align: center;

  button {
    background-color: #ffffff20;
    border: 1px solid #fff;
    color: #fff;
    padding: 8px 14px;
    margin: 10px 0;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: #ffffff60;
    }
    &:focus {
      outline: none;
    }
  }
}
