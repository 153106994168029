@import 'src/utils/colors/colors.scss';

.Leaderboard {
  color: #eee;
  border: 2px solid #ddd;
  border-radius: 8px;

  // TODO: Move positioning css out of Leaderboard component
  box-sizing: border-box;
  max-width: 300px;
  min-height: 50%;
  min-width: 300px;
  margin-right: 10px;
  &:first-of-type {
    margin-left: auto;
  }
  &:last-of-type {
    margin-right: auto;
  }
  @media only screen and (max-width: 600px) {
    min-width: 95%;
    height: 100%;
  }

  .title {
    border-bottom: 2px solid #ddd;
    padding: 8px 12px;
    text-align: center;
    font-weight: 500;
  }

  .leaderboard-row {
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 16px;
    margin: 10px;
    overflow: hidden;
    background-color: #ffffff10;

    .nickname {
      flex: 1;
      margin-right: 14px;
    }

    .points {
      padding: 6px 12px;
      background-color: #ddd;
      color: #111;
      font-size: 1rem;
      font-weight: bold;

      .added-points {
        font-size: 0.7em;
      }

      @include colors-list;
    }
  }
}
