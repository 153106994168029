.VolumeAdjuster {
  display: flex;
  margin: 4px 0;

  .sound-icon {
    font-size: 18px;
    cursor: pointer;
    user-select: none;
  }

  input[type='range'] {
    width: 100%;
    margin: 0 4px;
  }
}
