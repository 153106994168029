.GameSquare {
  position: relative;
  height: 116px;
  width: 116px;
  margin: 4px 2px;
  background-color: #ffffff10;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 1;

  &.large {
    height: 140px;
    width: 140px;
  }

  .main-content {
    padding: 4px;
    overflow: hidden;
    flex: 1;

    .context {
      font-size: 0.7em;
      color: #ffffffaa;
      overflow: auto;
      height: 60%;

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px 10px #1a1a1a;
        border: solid 3px transparent;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 10px 10px #ccc;
        border: solid 4px transparent;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb:hover {
        box-shadow: inset 0 0 10px 10px #aaa;
        border: solid 3px transparent;
      }
    }
  }

  .actions {
    display: flex;
    width: 100%;
    font-size: 13px;

    button {
      flex: 1;
      opacity: 0.8;
      transition: opacity 0.1s;
      cursor: pointer;
      border: 0;
      font-size: inherit;
      padding: 2px;
      background-color: #398ddc;
      color: #fff;

      &:hover {
        opacity: 1;
      }
    }

    .add {
      background-color: green;
      padding: 5px;
    }

    .settings,
    .remove {
      color: #fff;
      font-size: 11px;
      padding: 5px;
    }
    .settings {
      background-color: #398ddc;
    }
    .remove {
      background-color: #b33737;
    }

    .single-button {
      padding: 5px;
      font-size: revert;
    }
  }

  .background {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -5;
    position: absolute;
    overflow: hidden;
    opacity: 0.4;
    user-select: none;

    img {
      height: 100%;
      width: 100%;
    }
  }

  // in-list

  .players-range {
    font-size: 0.8em;
  }

  // selected

  .settings-holder {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #00000066;
    display: flex;
    justify-content: center;
    align-items: center;

    .box {
      position: relative;
      width: fit-content;
      margin: 10px auto;
      background-color: #202124;
      padding: 14px;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

      .OptionBox {
        margin-bottom: 8px;
      }

      button {
        background-color: #ffffff20;
        border: 1px solid #fff;
        color: #fff;
        padding: 8px 14px;
        font-size: 18px;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
          background-color: #ffffff60;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
