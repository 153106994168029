.SquareButton {
  height: 130px;
  width: 130px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  transition: background-color 0.1s;
  font: inherit;
  text-align: center;

  .image-holder {
    flex: 1;
  }

  .text-holder {
    text-align: center;
    margin: 8px auto;
  }

  &.white {
    border: 1px solid #fff;
    background-color: #ffffff20;
    color: #fff;
  }
  &.red {
    border: 1px solid #dc3939;
    background-color: #dc393920;
    color: #eee;

    &:hover {
      background-color: #dc393960;
    }
  }
  &.blue {
    border: 1px solid #3982dc;
    background-color: #3982dc20;
    color: #eee;

    &:hover {
      background-color: #3982dc60;
    }
  }
  &.green {
    border: 1px solid #3cdc39;
    background-color: #3cdc3920;
    color: #eee;

    &:hover {
      background-color: #3cdc3960;
    }
  }
  &.yellow {
    border: 1px solid #d9dc39;
    background-color: #d9dc3920;
    color: #eee;

    &:hover {
      background-color: #d9dc3960;
    }
  }
  &.lightblue {
    border: 1px solid #39dcdc;
    background-color: #39dcdc20;
    color: #eee;

    &:hover {
      background-color: #39dcdc60;
    }
  }

  &:disabled {
    cursor: default;
    border: 1px solid #9e9e9e;
    background-color: #9e9e9e20;
    color: #eee;
    &:hover {
      background-color: #9e9e9e20;
    }
  }
}
