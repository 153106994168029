.Leaderboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  text-align: center;
  color: #fff;
  overflow: auto;

  table {
    max-width: 800px;
    margin: 0 auto;

    th {
      padding: 6px 4px;
      background-color: #3a4252;
      font-size: 12px;
    }

    td {
      text-overflow: ellipsis;
      padding: 2px 4px;
      background-color: #2a303b;
    }
  }
}
