@mixin colors-list {
  &.green {
    background-color: #228b22;
    color: #eee;
  }
  &.blue {
    background-color: #4169e1;
    color: #eee;
  }
  &.red {
    background-color: #ff6347;
  }
  &.orange {
    background-color: #ffa07a;
  }
  &.yellow {
    background-color: #ffd700;
  }
  &.purple {
    background-color: #9370db;
    color: #fff;
  }
  &.pink {
    background-color: #ffb6c1;
  }
  &.aqua {
    background-color: #00ced1;
  }
  &.lime {
    background-color: #00ff00;
  }
  &.brown {
    background-color: #8b4513;
    color: #fff;
  }
  &.gold {
    background-color: #ffc400;
  }
}

@mixin colors-list-text {
  &.green {
    color: #228b22;
  }
  &.blue {
    color: #4169e1;
  }
  &.red {
    color: #ff6347;
  }
  &.orange {
    color: #ffa07a;
  }
  &.yellow {
    color: #ffd700;
  }
  &.purple {
    color: #9370db;
  }
  &.pink {
    color: #ffb6c1;
  }
  &.aqua {
    color: #00ced1;
  }
  &.lime {
    color: #00ff00;
  }
  &.brown {
    color: #8b4513;
  }
  &.gold {
    color: #ffc400;
  }
}
