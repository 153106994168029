.OptionBox {
  display: flex;
  justify-content: center;

  .name {
    border: 1px solid #fff;
    background-color: #ffffff30;
    color: #fff;
    font-size: 16px;
    padding: 6px 8px;
    border-radius: 5px 0 0 5px;
    border-right: 0;
  }

  input {
    border: 1px solid #fff;
    background-color: #ffffff20;
    color: #fff;
    font-size: 16px;
    padding: 6px 8px;
    border-radius: 0 5px 5px 0;
    max-width: 40px;

    &:focus {
      outline: none;
      background-color: #ffffff25;
    }

    &[type='text'] {
      min-width: 100px;
    }
  }

  select {
    border: 1px solid #fff;
    border-radius: 0 5px 5px 0;
    padding: 6px 8px;
    background-color: #ffffff20;
    color: #fff;
    outline: none;

    option {
      color: #fff;
      background-color: #282c34;

      &:hover {
        background-color: #aaa;
      }
    }
  }
}
